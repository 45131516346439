<template lang="pug">
modal(class='form-project-add-products flex flex-1 flex-col' size='lg' mode='drawer' @close='$emit("close")')
  div(ref='container' @dragover.prevent.stop='false')
    template(v-if='productSearch.attributesReady')
      div(class='flex flex-1 flex-col')
        form-header
          div(class='flex items-center')
            span(class='mr-2') {{ $t('Add to Project') }}
            pill(v-if='total < 5000' type='faded' :data-tooltip='$tc("No Results | {0} Result | {0} Results", total)') {{ total }}
        list-filter-input(name='term' class='-mt-2 mb-4' :placeholder='$t("Search..")')
        products-filters(v-model:viewSettings='productSearch.viewSettings' :attributes='attributes' :selectedFilters='selectedFilters' :counts='counts' @clearAll='clearFilters()')
          list-filter(v-slot='{ change, value, clear }' name='categories' :initialValue='[]')
            list-filter-dropdown(:value='value.length || false' :label='$t("Categories")' @clear='clear')
              div(class='-mx-4 min-w-[300px] p-0 pb-3 pl-10 md:mx-0')
                control-tree(class='mt-2' :options='initialAttributes.categories.values || []' :hide='hideCategoryCheck' :modelValue='value ? value[0] : ""')
                  template(#default='{ item, selected }')
                    btn(plain class='-ml-1 flex !min-h-0 !px-2 !py-0' :active='selected' @click='change([item.id])')
                      span(class='mr-1 text-base') {{ item.title }}
                      pill(v-if='counts.categories && counts.categories[item.id]' type='faded') {{ counts.categories[item.id] }}
        grid-group(class='mb-10 flex-1' :minWidth='viewSettings === "default" ? "220px" : "300px"')
          product-grid-item(
            v-for='(item, index) in items'
            :id='item.id'
            :key='item.id'
            :to='$modals.getLocation("modal-product-detail", { productId: item.id })'
            :title='item.title'
            :image='item.image'
            :brand='item.brand.title'
            :data-id='item.id'
            :data-index='index'
            :modelValue='selectedMap[item.id]'
            :retailPriceNetString='item.retailPriceNet'
          )
          empty-state(v-if='items.length === 0' centered class='col-span-2' size='small' bordered style='min-height: 400px' :title='$t("Product or brand not found?")')
            template(#image)
              div
                img(class='mb-5' src='/images/application/empty-state-furniture.svg' style='width: 92px; height: 92px')
            template(#message)
              span(
                v-add-element-attributes='{ a: { href: $router.resolve({ name: "/projects/" }).href } }'
                v-html='$t("You can add external products in your <a>projects</a> or request any product here:")'
              )
            template(#buttons)
              btn(v-contact-link='{ message: $t("I am looking for the product") + " " }' class='mt-3' icon='message-square') {{ $t('Request product') }}
      pagination(v-show='items.length > 0' v-model='page' v-model:pageSize='pageSize' :total='total' :pageSizeOptions='pageSizes')
      sticky-container(position='bottom' :enabled='hasSelected')
        action-bar(class='py-4')
          btn(v-if='hasSelected' tertiary icon='rotate-ccw' data-test='btn-reset-product-selection' @click='clearSelected') {{ $t('reset') }}
          btn(primary class='font-bold' :disabled='!hasSelected' data-test='btn-select-products-submit' @click='submit')
            span(v-html='$tc("Add {0} product(s)", selectedItems.length)')
      overlay(:visible='loadingSubmit')
</template>

<script setup lang="ts">
import { useProjectsStore } from '../store'
import ControlTree from '@/components/Control/ControlTree.vue'
import GridGroup from '@/components/GridGroup.vue'
import ListFilter from '@/components/List/ListFilter.vue'
import ListFilterDropdown from '@/components/List/ListFilterDropdown.vue'
import ProductGridItem from '@/components/ProductGridItem.vue'
import { useGenericKeyValueStore, usePageTitle, useSelectItems } from '@/composables/'
import { useInquiriesStore } from '@/pages/inquiries/store'
import ProductsFilters from '@/pages/products/components/ProductsFilters.vue'
import { useProductSearch } from '@/pages/products/composables'
import { useTranslation } from '@/plugins/translation'
import type { IClipboardItem, INestedOption } from '@/types'
import { type AxiosResponse } from 'axios'
import Axios from 'axios-observable'
import { ref } from 'vue'
import { computed } from 'vue'

const props = defineProps({
  projectId: { type: String, required: true },
  inquiryId: { type: String, required: true },
  tag: { type: String, required: true },
  productConfigId: String,
})
const { $t } = useTranslation()
usePageTitle($t('Products'))
const container = ref<HTMLElement | null>(null)

const { setValue, getValue } = useGenericKeyValueStore()
if (!getValue('clipboard')) {
  Axios.get('/_/Clipboard').subscribe({
    next: (r: AxiosResponse<{ items: IClipboardItem }>) => setValue('clipboard', r.data.items),
  })
}

const emit = defineEmits(['close'])
const productSearch = useProductSearch(false)
const { viewSettings, attributes, initialAttributes, counts, pageSizes } = productSearch
const { items, selectedFilters, total, clearFilters, page, pageSize } = productSearch.list
const cancel = () => emit('close')
const { projectId, tag, inquiryId } = props
const store = useProjectsStore()
const inqStore = useInquiriesStore()
const project = computed(() => store.project(projectId))
if (!project.value) {
  store.loadProject(projectId)
}
const selectedItems = ref<string[]>([])
const hasSelected = computed(() => selectedItems.value.length > 0)
const addProductsToProjectOrInquiry = async (selectedItems: string[], index: number = 0, parentProductId?: string) => {
  if (inquiryId) {
    await inqStore.addProductsToInquiry(projectId, inquiryId, '', selectedItems, index)
  } else {
    await store.addProducts(projectId, '', selectedItems, tag, index, parentProductId)
  }
}

const { selectedMap, clearSelected } = useSelectItems(selectedItems, container)

const loadingSubmit = computed(
  () => store.loading['addProductsToInquiry'] || store.loading['addProductsToTag'] || store.loading['addProducts'],
)
const submit = async () => {
  await addProductsToProjectOrInquiry(selectedItems.value)
  cancel()
}
const hideCategoryCheck = (option: INestedOption) => option.id !== 'not_relevant'
</script>
